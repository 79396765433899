
























import Vue from "vue";
import Spinner from "./button-icons/Spinner.vue";
const LOADER: any = {
  color: {
    'default': '#fff',
    'primary': '#314482',
    'secondary': '#F11C33'
  }
}
export default Vue.extend({
  components: {
    Spinner
  },
  props: {
    type: {
      type: String,
      default: "button"
    },
    color: {
      type: String,
      default: ""
    },

    outline: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: true
    },

    full: {
      type: Boolean,
      default: false
    },

    btnClass: {
      type: String,
      default: ""
    },

    btnHeight: {
      type: String,
      default: "h-10"
    },

    text: {
      type: String,
      default: ""
    },

    icon: {
      type: String,
      default: ""
    },

    iconOption: {
      type: Object,
      default(){
        return{}
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedClasses(): any[] {
      return [
        "outline-none focus:outline-none",
        this.btnColors,
        this.rounded && "rounded",
        this.full && "w-full",
        this.btnHeight,
        this.loading && "loading",
        this.disabled && "disabled"
      ];
    },

    btnColors() {
      let color = "";
      switch (this.color) {
        case "primary":
          color = this.outline
            ? "bg-transparent border-2 border-primary-100 text-primary-100 hover:border-primary-200 hover:text-primary-200"
            : "text-white bg-primary-100 hover:bg-primary-200";
          break;

        case "secondary":
          color = this.outline
            ? "bg-transparent border-2 border-secondary-100 text-secondary-100 hover:border-secondary-200 hover:text-secondary-200"
            : "text-white bg-secondary-100 hover:bg-secondary-200";
          break;
      }
      return color;
    },

    loader(): any{
      const color: string = this.color || '';
      const loader = {
        width: '1.7rem',
        color: LOADER.color.default
      }

      if (this.outline && this.color) {
        loader.color = LOADER.color[color]
      }
      return loader
    }
  }
});
