import { Api, API_URL } from "@/api"

const actions = {
  sendByEmail(_: any, {transactionId, data: {email} }: any){
    return Api.post(`${API_URL.SEND_EMAIL}/${transactionId}`, {email: email})
    
  },

  sendBySms(_: any, {transactionId, data: {phoneNumber,countryCode}}: any){
    return Api.post(`${API_URL.SEND_SMS}/${transactionId}`, {phoneNumber,countryCode})
  }
}

export default {
  namespaced: true,
  actions
}