import { Api, API_URL } from "@/api"

const state = {
  activities: []
}

const actions = {
  getAllActivity({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.ACTIVITY}/all`, {params}).then(response => {
        state.activities = response.data

        resolve(response.data)
      })
      .catch(err => reject(err.response))
    })
  },
}

export default {
  namespaced: true,
  state,
  actions
}