









































































import Vue from 'vue'
import PciIcon from '@/assets/icons/pci.svg'
import OpenSiIcon from '@/assets/icons/opensi.svg'

export default Vue.extend({
  components: {
    PciIcon,
    OpenSiIcon
  }
})
