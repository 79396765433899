import { Api, API_URL } from "@/api"

const state = {
  roles: []
}

const getters = {
  roles(state: any){
    return state.roles
  }
}

const actions = {
  getAllRole({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.ROLE}`, {params}).then(response => {
        state.roles = response.data

        resolve(state.roles)
      })
      .catch(err => reject(err.response))
    })
  },
  getAdminRole({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.ADMIN}/roles`, {params}).then(response => {
        state.roles = response.data

        resolve(state.roles)
      })
      .catch(err => reject(err.response))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions
}