import axios from "axios";

export const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://192.168.100.235:3000'
})
const baseURL = process.env.VUE_APP_API_URL || 'http://192.168.100.235:3000'
export const API_URL = {
  CUSTOMER: 'users',
  TRANSACTION: 'transaction',
  SEND_EMAIL: 'bills/sendbyemail',
  SEND_SMS: 'bills/sendbysms',
  STATS: 'stats',
  LOGIN: 'admin/sign_in',
  CHECK_TOKEN: `${baseURL}/admin/check`,
  INVITATION: 'invitations',
  ADMIN:'admin',
  ROLE:'roles',
  ACTIVITY: 'activity',
  SETTINGS: 'settings'
}
Api.interceptors.response.use(function(response) {
  return response
},function(error) {
  if (error.response) {
    if (error.response.status === 401) {
      // console.log(error.response);
      window.dispatchEvent(new CustomEvent('notauthenticated' , {detail: {fullpath: window.location.pathname}}))
    }
  }


})
export const IO_URL = process.env.VUE_APP_IO_URL || 'http://localhost:3000'

export const IO_PATH = process.env.VUE_APP_IO_PATH || '/'
