




























































import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  props: {
    label: String,

    name: {
      type: String
    },
    autocomplete: {
      type: String
    },

    placeholder: {
      type: String
    },

    readonly: {
      type: String,
    },

    wapperClass: {
      type: String,
      default: `border-2 focus-within:border-primary-100 border-blue-80`
    },

    inputClass: {
      type: String,
      default: 'rounded border-none px-2 border-0 w-full focus:outline-none'
    },

    id: {
      type: String,
      default() {
        if (this.name) {
          const name: string = this.name;
          return name
            .trim()
            .toLowerCase()
            .replace(/ /g, "-");
        }
      }
    },

    value: {},

    radioValue: {},

    type: {
      type: String,
      default: "text"
    },

    inputmode: {
      type: String,
      default: "text"
    },

    checked: {
      type: Boolean,
      default: false
    },

    displayErrors: {
      type: Boolean,
      default: true
    },
    
    errors: {
      type: Array,
      default(){
        return []
      }
    },

  },
  data() {
    return {
      model: this.value || "",
      check: this.checked
    };
  },
  methods: {
    setInitialValueForInput(value: any) {
      // console.log("inital value for input", value);
      if (this.type === "radio") {
        if (this.checked) {
          this.$emit("input", value);
        }
      } else if (this.type === "checkbox") {
        this.$emit("input", this.checked);
      } else {
        this.$emit("input ", value);
      }
    }
  },

  computed: {
    listeners(this: any) {
      return {
        ...this.$listeners,
        input: (event: any) => {
          this.model = event.target.value;

          this.$emit("input", event.target.value);
        },

        focus: (event: any) => {
          this.$emit("focus", event);
        },

        blur: (event: any) => {
          this.$emit("blur", event);
        },

        change: (event: any) => {
          if (this.type === "checkbox") {
            this.$emit("input", event.target.checked);
            this.$emit("change", event.target.checked);
            this.check = event.target.checked;
          } else if (this.type === "radio") {
            this.check = event.target.checked;
            this.$emit("change", event.target.checked);
            if (this.radioValue) {
              this.$emit("input", this.radioValue);
            }
          } else {
            this.$emit("change", event.target.value);
          }
        }
      };
    },

    attributes() {
      // console.log("attr " ,this.$attrs,);
      
      return {
        ...this.$attrs,
        value: this.value,
        // placeholder: this.placeholder,
        autocomplete: this.autocomplete
      };
    },

    checkable() {
      return this.type === "radio" || this.type === "checkbox";
    },

    isChecked(this: any) {
      if (this.type === "checkbox") {
        return this.check === true ? true : false;
      }

      if (this.type === "radio") {
        return this.radioValue === this.value || this.check === true
          ? true
          : false;
      }

      return false;
    },

    wapperClassList(this: any) {
      const classes = ["flex w-full wapper-class rounded select-none"];

      // if (this.tagInput) {
      //   classes.push("min-h-11");
      // } else {
      //   classes.push("h-11");
      // }
      classes.push("h-11");
      if (!this.checkable) {
        classes.push(this.wapperClass
        );
      }
      return classes;
    },
  },


  created(this: any) {
    if (this.value && this.value != null) {
      // if (this.tagInput) {
      //   this.setInitialValueForTagInput(this.value);
      //   console.log('initial value ---> ', this.value);
        
      // } else {
      //   this.setInitialValueForInput(this.value);
      // }
      this.setInitialValueForInput(this.value);
    }
  },

  beforeDestroy(this: any){
    // if (this.tagInput) {
    //   console.log('beforeDestroy tagInput -->');
    //   this.tagValues = []
    // }
    
  }
});
