
import layout from '@/layouts/default.vue'
import authlayout from '@/layouts/auth.vue'
const routes: any = [
  {
    path: '/',
    component: layout,
    children: [
      {
        path: "/",
        icon: "Operation",
        title: "Opérations",
        name: "operation",
        component: () => import("@/pages/operation/operation.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/supervision",
        icon: "Eye",
        title: "Supervision",
        name: "supervision",
        component: () => import("@/pages/supervision/supervision.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/statistique",
        icon: "Bar",
        title: "Statistiques",
        name: "statistique",
        component: () => import("@/pages/statistique/statistique.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/admin",
        icon: "Eye",
        title: "Utilisateurs",
        name: "utilisateurs",
        component: () => import("@/pages/admin/admin.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/activity",
        icon: "Log",
        title: "Journal",
        name: "activity",
        component: () => import("@/pages/activity/activity.vue"),
        meta: { requiresAuth: true }
      },
    ]

  },
  {
    path: "/",
    component: authlayout,
    children: [
      {
        path: "login",
        icon: "",
        title: "Connexion",
        name: "Connexion",
        component: () => import("@/pages/login/login.vue")
      },
      {
        path: "register",
        icon: "",
        title: "Register",
        name: "register2",
        component: () => import("@/pages/register/register.vue")
      },
      {
        path: "invitations/confirmed/:reference",
        icon: "",
        title: "Register",
        name: "register",
        component: () => import("@/pages/register/register.vue")
      },
    ]
  },
]
export default routes;
