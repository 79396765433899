









































import Vue from "vue";
import IconClose from "@/assets/icons/time.svg";
export default Vue.extend({
  components: {
    IconClose
  },

  props: {
    tag: {
      type: String,
      default: "div"
    },

    css: {
      type: Object,
      default() {
        return {};
      }
    },

    title: {
      type: String,
      default: "Modal title "
    },

    size: {
      type: String,
      default: "md"
    },

    fullSizePosition: {
      type: String,
      default: ""
    },

    position: {
      type: String,
      default: ""
    },

    show: {
      type: Boolean,
      default: false
    },

    centered: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: true
    },

    noBackground: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    selfClick() {
      this.$emit("close", this);
    }
  },

  computed: {
    wrapperClass():  string[] {
      return [
        "modal",
        this.noBackground ? "modal-without-background": '',
        this.centered ? "flex justify-center items-center": ''
      ];
    },

    dialogClass(): string[] {
      return [
        "modal-dialog max-h-full",
        this.position || this.fullSizePosition
          ? "fixed m-0 "
          : "relative my-6 mx-auto ",
        this.modalPositon,
        this.modalSize,
        this.modalFullSizePosition,
        this.scrollable ? "overflow-y-scroll" : ""
      ];
    },

    modalPositon(this: any): string {
      let position = "";
      switch (this.position) {
        case "top":
          position = "top-0";
          break;

        case "bottom":
          position = "bottom-0";
          break;

        case "left":
          position = "left-0";
          break;

        case "right":
          position = "right-0";
          break;

        case "top-left":
          position = "top-6 left-6";
          break;

        case "top-right":
          position = "top-6 right-6";
          break;

        case "bottom-left":
          position = "bottom-6 left-6";
          break;

        case "bottom-right":
          position = "bottom-6 right-6";
          break;
      }
      return position;
    },

    modalSize(this: any): string {
      let size = "";
      switch (this.size) {
        case "sm":
          size = "w-10/12 lg:w-3/12";
          break;
        case "md":
          size = "w-10/12 lg:w-5/12";
          break;

        case "lg":
          size = "w-11/12 lg:w-10/12";
          break;
        case "fluid":
          size = "w-full";
          break;
      }
      if (!size) {
        size = this.size
      }
      return size;
    },

    modalFullSizePosition(this: any): string {
      let fullSizePosition = "";

      switch (this.fullSizePosition) {
        case "top":
          fullSizePosition = "w-full lg:w-full top-0";
          break;

        case "bottom":
          fullSizePosition = "w-full lg:w-full bottom-0";
          break;

        case "left":
          fullSizePosition = "h-full lg:h-full left-0";
          break;

        case "right":
          fullSizePosition = "h-full lg:h-full right-0";
          break;

        default:
          break;
      }
      return fullSizePosition;
    },

    contentClass() {
      return ["modal-content"];
    },

    computedClass() {
      return {
        ...this.css,
        titleClass: "text-xl font-semibold"
      };
    }
  }
});
