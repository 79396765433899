






import Vue from "vue";
export default Vue.extend({
  props: {
    width: {
      type: String,
      default: "1.7rem"
    },
    stroke: {
      type: String,
      default: "#fff"
    }
  }
});
