import Vue from "vue";
import {
  setInteractionMode,
  localize,
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate";
import { required, email, numeric, oneOf, min, confirmed } from "vee-validate/dist/rules";
import fr from './fr.json'

// No message specified.
extend("numeric", numeric);

extend("email", email);

extend("oneOf", oneOf);

extend("min", min);
extend("confirmed", confirmed)

// extend('min_uppercase', {
//   validate: (value: string, { min = 1 }: any) => {
//     const match = value.match(/[A-Z]+/g)
//     if (! Array.isArray(match)) {
//       return false
//     }
//     return match.length >= min
//   },
//   params:['min'],
//   message: "Le champ {_field_} doit avoir au moins {min} lettre majuscule"
// });

// extend('min_number', {
//   validate: (value: string, { min = 1 }: any) => {
//     const match = value.match(/[0-9]+/g)
//     if (! Array.isArray(match)) {
//       return false
//     }

//     return match.length >= min
//   },
//   params: ['min'],
//   message: "Le champ {_field_} doit avoir au moins {min} lettre majuscule"
// });

// extend('password', {
//   params: ['target'],
//   validate(value, { target }: any) {
//     return value === target;
//   },
//   message: 'La confirmation du mot de passe ne marche pas'
// });


// extend('min_spc_caracter', {
//   validate: (value: string, { min = 1 }: any) => {
//     const match = value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)

//     if (!Array.isArray(match)) {
//       return false
//     }
//     return match.length >= min;
//   },
//   message: "Le champ {_field_} doit avoir au moins {min} lettre majuscule"
// });

extend('min_uppercase', {validate: value => value.match(/[A-Z]+/g) !== null, message: 'Le champ {_field_} doit contenir au moins une majuscule'});
extend('min_number', {validate: value => value.match(/[0-9]+/g) !== null, message: 'Le champ {_field_} doit contenir au moins un chiffre'});
extend('min_spc_caracter', {validate: value => value.match(/^(?=.[!"#$%&'()+,-./:;<=>?@[\]^`{|}~])/g) !== null, message: 'Le champ {_field_} doit contenir au moins un caractère spécial'});

// Override the default message.
extend("required", required);

localize("fr", fr);
setInteractionMode("eager");

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
