



















import Vue from 'vue'
export default Vue.extend({
  computed: {
    item(this: any) {
      const item = this.$router.options.routes[0].children;
      // console.log("item ", item);

      return item;
    }
  },

  methods: {
    routeTo(path: string){
      this.$router.push({path})
      console.log('close-menu',);
      
      this.$emit('close')
    },
    logout(){
      this.$store.dispatch('auth/logout')
    }
  }
})
