

































import Vue from 'vue'
import KkiapayLogo from '@/assets/icons/logo.svg'
import MenuIcon from '@/assets/icons/menu.svg'
import MobileMenu from './MobileMenu.vue'
export default Vue.extend({
  components: {
    KkiapayLogo,
    MenuIcon,
    MobileMenu
  },

  data(){
    return {
      open: false
    }
  }
})
