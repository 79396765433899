import { Api, API_URL } from "@/api"
import axios from "axios"

const state = {
  auth: null,
  token: '',
  authenticated: false
}
const getters = {
  authenticated(state: any){
    return state.authenticated
  },

  auth(state: any){
    return state.auth
  }
}
const mutations = {
  setAuth(state: any, auth: any){
    console.log('auth --->', auth);
    
    state.auth = auth
    state.token = auth.token
    
    // console.log('token ', auth.token);
    
    Api.defaults.headers.common['Authorization'] = `Bearer ${state.token}`
    state.authenticated = true;
    console.log('authenticated state ', state.authenticated);
    
    localStorage.setItem('sbee_back_off', auth.token)
  }
}
const actions = {
  loginByEmail({ commit}: any, {email,password}: any){
    return new Promise((resolve, reject) => {
      Api.post(API_URL.LOGIN, {email, password})
      .then(response => {
        console.log();
        const auth = response.data.user || {};
        auth.token = response.data.token
        commit('setAuth', auth)
        resolve(response.data)
      })
      .catch(error => {
        
        if (error.response) {
          reject(error.response)
        }
        reject(error)
      })
    })
  },

  checkAdminToken({commit}: any, token: string){
    console.log('checkAdminToken: ===>',API_URL.CHECK_TOKEN);
    
   return new Promise((resolve, reject) => {
     axios.get(API_URL.CHECK_TOKEN, {
       headers: {
         'Authorization': `Bearer ${token}`
       }
     })
     .then(response => {
      //  console.log('check auth -->', response.data);
       const auth =  response.data.user;
        auth.token = response.data.token;
        commit('setAuth', auth)
      resolve(true)
     })
     .catch(error => {
      resolve(false)
     })
   })
  },

  logout({state}: any){
    localStorage.removeItem('sbee_back_off')
    window.location.reload()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}