






import Vue from 'vue'
export default Vue.extend({
  mounted() {
    window.addEventListener('notauthenticated', () => {
      // console.log('notauthenticated event', e);
      window.location.reload()
    })
  }
})
