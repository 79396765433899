














import Vue from 'vue'
import KkiapayLogo from '@/assets/icons/logo.svg'
import Footer from '@/components/Footer.vue'
export default Vue.extend({
  components:{
    KkiapayLogo,
    Footer
  }
})
