import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { i18n, router } from './plugins'
import VueTelInput from 'vue-tel-input'
import VCalendar from 'v-calendar';
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.config.productionTip = false

import SideBar from '@/components/SideBar.vue'
import NavBar from '@/components/NavBar.vue'
import VNavItem from '@/components/VNavItem.vue'
import Footer from '@/components/Footer.vue'
import VModal from '@/components/modal/VModal.vue'
import VInput from '@/components/VInput/VInput.vue'
import VButton from '@/components/VButton.vue'
import IconClose from "@/assets/icons/time.svg";
import { DateTime } from 'luxon'

function luxonFormater(date: any, format: any) {
  const d = new Date(date)
  return DateTime.fromJSDate(d).toLocaleString(format)
}

Vue.use(VueTelInput)
Vue.use(VCalendar)
Vue.component('SideBar',SideBar)
Vue.component('NavBar',NavBar)
Vue.component('VNavItem',VNavItem)
Vue.component('Footer',Footer)
Vue.component('VModal',VModal)
Vue.component('VInput',VInput)
Vue.component('VButton',VButton)
Vue.component('IconClose',IconClose)
Vue.filter('formatPipe', function(date: any, format: any) {
  return luxonFormater(date, format)
})
Vue.mixin({
  methods: {
    luxonFormat(date: any, format: any) {
      return luxonFormater(date, format)
    }
  }
})




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
