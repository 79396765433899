import { Api, API_URL } from "@/api"

const state = {

}

const actions = {
  getAllCustomer({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.CUSTOMER}/all`, {params}).then(response => {
        state.customers = response.data

        resolve(response.data)
      })
      .catch(err => reject(err.response))
    })
  },

  getFilteredCustomer({state}: any, params = {}){
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.CUSTOMER}`, {params}).then(response => {
        state.customers = response.data

        resolve(response.data)
      })
      .catch(err => reject(err.response))
    })
  },

  changeAccountState({state}: any, {userId}: any){
    return new Promise((resolve, reject) => {
      Api.post(`${API_URL.CUSTOMER}/mutUserStatus`, {userId})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error.response))
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}