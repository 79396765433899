




















import Vue from 'vue'
import VNavItem from './VNavItem.vue';
export default Vue.extend({
  components: { VNavItem },

  methods: {
    logout(){
    // 
      this.$store.dispatch('auth/logout')
      
    }
  },

  computed: {
    item(this: any) {
      const item = this.$router.options.routes[0].children;
      // console.log("item ", item);

      return item;
    }
  }
})
