import { Api, API_URL } from "@/api"
import { SendInvitation } from "@/interfaces"

const state = {
  admins: []
}

const actions = {
  getAllAdmin({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.ADMIN}/all`, {params}).then(response => {
        state.admins = response.data

        resolve(response.data)
      })
      .catch(err => reject(err.response))
    })
  },

  getInvitedAdmin({state}: any,params = {}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.INVITATION}`, {params}).then(response => {
        state.admins = response.data

        resolve(state.admins)
      })
      .catch(err => reject(err.response))
    })
  },


  sendInvitation({state}: any, {invitation, code }: any ) {
    const params: any = {}
    if (code) {
      params['code'] = code
    }
    return new Promise((resolve, reject) => {
      Api.post(API_URL.INVITATION, invitation, {params}).then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
    })
  },

  createAdminByInvitation({commit}: any, {adminData, reference}: any) {
    return new Promise((resolve, reject) => {
      Api.post(`${API_URL.INVITATION}/confirmed/${reference}`, adminData).then(response => {
        console.log('response ', response);
        
        const auth = response.data.user;
        auth.token = response.data.token
        commit('auth/setAuth', auth, {root: true})
        resolve(response.data)
      })
      .catch(error => reject(error))
    });
  },

  sendInvitationOtp(_: any, otpData: any){
    return new Promise((resolve, reject) => {
      Api.post(`${API_URL.INVITATION}/send-otp`, otpData).then(response  => {
        resolve(response.data)
      })
      .catch(error => reject(error.response))
    })
  },

  checkInvitationOtp(_: any, opt: any) {
    return new Promise((resolve, reject) => {
      Api.post(`${API_URL.INVITATION}/check-otp`, opt)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error.response))
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}