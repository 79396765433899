import { Api, API_URL } from "@/api"

const state = {
  transactions: []
}

const actions = {
  getAllTransaction({state}: any, params ={}) {
    return new Promise((resolve, reject) => {
      Api.get(`${API_URL.TRANSACTION}/all`, {params}).then(response => {
        state.transactions = response.data
        resolve(response.data)
      })
      .catch(error => reject(error.response))
    })
  },


  regenerateCode({state}: any, data: any){
    return new Promise((resolve, reject) => {
      Api.post(`${API_URL.TRANSACTION}/fix/vending/${data.id}`, {}).then(response => {
        
        resolve(response.data)
      })
      .catch(error => reject(error.response))
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}