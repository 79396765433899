import Vue from 'vue'
import Vuex from 'vuex'
import customers from './modules/customers'
import admins from './modules/admins'
import transactions from './modules/transactions'
import services from './modules/services'
import auth from './modules/auth'
import roles from './modules/roles'
import activity from './modules/activity'
import { Api, API_URL } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stat:{},
    balance: 0,
    alertAmount: 0
  },
  mutations: {
  },
  actions: {
    getAllStat(_: any, params = {}){
      return new Promise((resolve, reject) => {
        Api.get(`${API_URL.STATS}`,  {params})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error.response))
      })
    },

    getBalance({state}: any) {
      return new Promise((resolve, reject) => {
        Api.get(`${API_URL.TRANSACTION}/balance`)
        .then(response => {
          resolve(response.data)
          state.balance =  parseInt(response.data.balance)
        })
        .catch(error => reject(error))
      })
    },
    getAlertAmount({state}: any) {
      return new Promise((resolve, reject) => {
        Api.get(`${API_URL.SETTINGS}/account`)
        .then(response => {
          resolve(response.data)
          state.alertAmount =  response.data.playload.alertAmount
        })
        .catch(error => reject(error))
      })
    },

    setAlertAmount({state}: any, alertAmount) {
      return new Promise((resolve, reject) => {
        Api.post(`${API_URL.SETTINGS}/account`,{playload: {alertAmount}})
        .then(response => {
          state.alertAmount =  response.data.playload.alertAmount
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    }
  },
  modules: {
    customers,
    admins,
    roles,
    transactions,
    services,
    activity,
    auth
  }
})
