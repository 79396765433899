import { IO_URL } from "@/api";
import { io } from "socket.io-client";

const socket = io(IO_URL, {transports: ['polling']});
// const socket = io('https://sbee-admin.kkiapay.me');
socket.on('connect', function(){
  console.log('Connected');
  
})
socket.on('disconnect', function(){
  console.log('Disconnect');
  
})
socket.on('connect_error',function(error){
  console.log('connect_error', error);
  
})
export {socket, io}