





















import Vue from "vue";
import Eye from "@/assets/icons/eye.svg";
import Bar from "@/assets/icons/bar.svg";
import Operation from "@/assets/icons/operation.svg";
import Logout from "@/assets/icons/logout.svg";
import Log from "@/assets/icons/log.svg"
export default Vue.extend({
  components: {
    Operation,
    Eye,
    Bar,
    Log,
    Logout
  },
  props: {
    name: {
      type: String,
      default: ""
    },

    title: {
      type: String,
      default: ""
    },

    icon: {
      type: String,
      default: ""
    },

    to: {
      type: String,
      default: ""
    },
    divide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.$route.name === this.name;
    }
  }
});
