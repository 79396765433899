import Vue from 'vue'
import Routes from '@/routes'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [...Routes]
})
router.beforeEach(async (to, from, next) => {
  let authenticated = store.getters['auth/authenticated'];
  // console.log('authenticated on guard ', authenticated);

  if (!authenticated) {
    const token = localStorage.getItem('sbee_back_off');
    if (token) {
      try {
        authenticated =  await store.dispatch('auth/checkAdminToken', token)
        if (!authenticated) {
          localStorage.removeItem('sbee_back_off')
        }
      } catch (error) {
        //
      }
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authenticated) {
      next({path: '/login', query: {redirect: to.fullPath}})
    }else{
      next()
    }
  }else {
    next()
  }
})


export default router;


